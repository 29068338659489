import React from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import _ from 'lodash'

function QuestionPage() {
  const navigate = useNavigate()
  const { questionId } = useParams()

  const [selectedOption, setSelectedOption] = React.useState(null)
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const [title, setTitle] = React.useState(null)
  const [question, setQuestion] = React.useState({
    questionText: '',
    options: [],
    answer: '',
    explanation: {
      japanese: '',
      chinese: '',
    },
  })
  const [todayAnsweredCount, setTodayAnsweredCount] = React.useState(0)
  const [nextQuestionId, setNextQuestionId] = React.useState(null)
  const [isMarkBtnEnabled, setIsMarkBtnEnabled] = React.useState(false)

  useEffect(() => {
    const accessKey = Cookies.get('accessKey')
    if (!accessKey) {
      navigate('/')
    }

    const fetchQuestion = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/question/${questionId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'x-access-key': accessKey,
            },
          },
        )
        const data = await response.json()
        console.log('data', data)
        const question = _.get(data, 'question.question')
        if (question) {
          setTitle(_.get(data, 'question.title'))
          setQuestion(JSON.parse(question))
          setNextQuestionId(null)
          setSelectedOption(null)
          setIsSubmitted(false)
        } else {
          alert('Failed to fetch the question!')
        }
      } catch (error) {
        console.error('Error fetching question:', error)
      }
    }

    fetchQuestion()
  }, [navigate, questionId])

  const handleOptionClick = (option) => {
    if (!selectedOption) {
      setSelectedOption(option)
    }
  }

  const handleMarkQuestionClicked = () => {
    const accessKey = Cookies.get('accessKey')
    fetch(`${process.env.REACT_APP_API_DOMAIN}/question/${questionId}/mark`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-key': accessKey,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          alert('Failed to mark the question! Please try again.')
          return
        }

        alert('Mark question clicked!')
        setIsMarkBtnEnabled(false)

        return response.json()
      })
      .then((data) => {
        console.log('Question marked:', data)
      })
      .catch((error) => {
        alert('Failed to mark the question! Error: ' + error)
        console.error('Error marking question:', error)
      })
  }

  const handleSubmit = () => {
    console.log('selectedOption:', selectedOption)

    setIsSubmitted(true)
    setIsMarkBtnEnabled(true)

    const isCorrect = selectedOption === question.answer
    const accessKey = Cookies.get('accessKey')
    fetch(`${process.env.REACT_APP_API_DOMAIN}/question/${questionId}/result`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-key': accessKey,
      },
      body: JSON.stringify({ isCorrect }),
    })
      .then((response) => {
        if (response.status !== 200) {
          alert('Failed to submit the result! Please try again.')
          return
        }

        return response.json()
      })
      .then((data) => {
        console.log('Result submitted:', data)

        // Get next question
        fetch(`${process.env.REACT_APP_API_DOMAIN}/question/next`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-access-key': accessKey,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log('next question data', data)
            const nextQuestionId = _.get(data, 'question.id')
            if (!nextQuestionId) {
              alert('Failed to fetch the next question!')
              return
            }

            setTodayAnsweredCount(_.get(data, 'todayAnsweredPageCount', 0))
            setNextQuestionId(nextQuestionId)
          })
          .catch((error) => {
            alert('Failed to fetch the next question! Error: ' + error)
            console.error('Error fetching next question:', error)
          })
      })
      .catch((error) => {
        alert('Failed to submit the result! Error: ' + error)
        console.error('Error submitting result:', error)
      })
  }

  return (
    <header className="bg-gray-800 text-white min-h-screen flex flex-col p-4 pb-20">
      <p className="mt-4 text-2xl font-black">{question.questionText}</p>
      <div className="mt-4">
        {question.options.map((option, index) => {
          let borderClass = ''
          if (isSubmitted) {
            if (selectedOption === option.key) {
              if (selectedOption === question.answer) {
                borderClass =
                  'border border-2 border-green-500 bg-white text-gray-800'
              } else {
                borderClass = 'border border-2 border-red-500'
              }
            } else if (option.key === question.answer) {
              borderClass = 'font-bold bg-white text-gray-800'
            }
          } else if (selectedOption === option.key) {
            borderClass = 'border border-white'
          }

          return (
            <p
              key={index}
              className={`mt-4 p-4 text-xl cursor-pointer ${borderClass}`}
              onClick={() => handleOptionClick(option.key)}
            >
              {option.key.toUpperCase() + '：' + option.text}
            </p>
          )
        })}
      </div>
      <div className="mt-8">
        {!isSubmitted && (
          <div className="mt-4 flex justify-center">
            <button
              className={`w-full text-xl px-4 py-2 rounded-md ${selectedOption ? 'bg-blue-500 hover:bg-blue-700' : 'bg-gray-500 cursor-not-allowed'}`}
              onClick={handleSubmit}
              disabled={!selectedOption}
            >
              Submit
            </button>
          </div>
        )}
        {isSubmitted && (
          <div className="mt-8">
            <p className="mt-4 text-2xl font-black">正解：{title}</p>
            <p className="mt-4">{question.explanation.japanese}</p>
            <p className="mt-4">{question.explanation.chinese}</p>
            <div className="mt-4 flex justify-center flex-col">
              <button
                className={`w-full text-xl px-4 py-2 cursor-pointer rounded-md ${nextQuestionId ? 'bg-blue-500 hover:bg-blue-700' : 'bg-gray-500 cursor-not-allowed'}`}
                onClick={() => navigate(`/question/${nextQuestionId}`)}
                disabled={!nextQuestionId}
              >
                Next
              </button>
              <button
                className={`w-full text-xl px-4 py-2 mt-4 cursor-pointer rounded-md ${isMarkBtnEnabled ? 'bg-yellow-500 hover:bg-yellow-700' : 'bg-gray-500 cursor-not-allowed'}`}
                onClick={() => handleMarkQuestionClicked()}
                disabled={!isMarkBtnEnabled}
              >
                Mark question
              </button>
            </div>
          </div>
        )}

        <p className="mt-4 text-center">Today : {todayAnsweredCount}</p>
        <p className="mt-4 text-center">{questionId}</p>
      </div>
    </header>
  )
}

export default QuestionPage
