import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

const HomePage = () => {
  const navigate = useNavigate()
  const [accessKey, setAccessKey] = useState('')

  const handleSubmit = () => {
    console.log('Input value:', accessKey)

    fetch(`${process.env.REACT_APP_API_DOMAIN}/authenticate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accessKey }),
    })
      .then((response) => response)
      .then((response) => {
        if (response.status !== 200) {
          alert(
            'Authentication failed! Please check your access key and try again.',
          )
          return
        } else {
          const expires = new Date()
          expires.setTime(expires.getTime() + 12 * 60 * 60 * 1000) // 12 hours in milliseconds
          document.cookie = `accessKey=${accessKey}; path=/; expires=${expires.toUTCString()};`

          fetch(`${process.env.REACT_APP_API_DOMAIN}/question/next`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-access-key': accessKey,
            },
          })
            .then((response) => {
              if (response.status !== 200) {
                alert('Failed to fetch the next question!')
                return
              }
              return response.json()
            })
            .then((data) => {
              console.log('data', data)
              const questionId = _.get(data, 'question.id')
              if (!questionId) {
                alert('Failed to fetch the next question!')
                return
              }

              navigate(`/question/${questionId}`)
            })
            .catch((error) => {
              console.error('Error fetching next question:', error)
              alert('Failed to fetch the next question! Error: ' + error)
            })
        }
      })
      .catch((error) => {
        console.error('Error:', error)
        alert('Authentication failed! Error: ' + error)
      })
  }

  return (
    <header className="bg-gray-800 text-white min-h-screen flex flex-col items-center justify-center">
      <p className="mt-4">Welcome to NipponApp!</p>
      <div className="mt-4">
        <input
          type="password"
          className="px-4 py-2 border rounded-md text-gray-800"
          placeholder="Input access key"
          value={accessKey}
          onChange={(e) => setAccessKey(e.target.value)}
        />
        <button
          className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </header>
  )
}

export default HomePage
