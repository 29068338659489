import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import QuestionPage from './QuestionPage'
import HomePage from './HomePage'

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/question/:questionId" element={<QuestionPage />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
